var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        [
          _c("h2", [_vm._v("Organizations")]),
          _vm._v(" ("),
          _c("span", { on: { click: _vm.gotoEvents } }, [
            _vm._v("see all games")
          ]),
          _vm._v(") "),
          _c("v-spacer"),
          _c("v-text-field", {
            attrs: {
              "append-icon": "search",
              label: "Search",
              "single-line": "",
              "hide-details": ""
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: {
            circle: "",
            absolute: "",
            top: "",
            small: "",
            right: "",
            fab: "",
            color: "orange"
          },
          on: {
            click: function($event) {
              return _vm.add()
            }
          }
        },
        [_c("v-icon", [_vm._v("add")])],
        1
      ),
      _c("v-data-table", {
        ref: "orgTable",
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.allOrgs,
          search: _vm.search,
          "item-key": "theKey"
        },
        scopedSlots: _vm._u([
          {
            key: "items",
            fn: function(props) {
              return [
                _c(
                  "tr",
                  {
                    key: _vm.itemKey(props.item),
                    staticClass: "sortableRow",
                    on: {
                      click: function($event) {
                        props.expanded = !props.expanded
                      }
                    }
                  },
                  [
                    _c(
                      "td",
                      { staticClass: "px-1", staticStyle: { width: "0.1%" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "sortHandle",
                            staticStyle: { cursor: "move" },
                            attrs: { icon: "" }
                          },
                          [_c("v-icon", [_vm._v("drag_handle")])],
                          1
                        )
                      ],
                      1
                    ),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.pushToGames(props.item)
                            }
                          }
                        },
                        [_vm._v(_vm._s(props.item.name))]
                      )
                    ]),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.description))
                    ]),
                    _c(
                      "td",
                      { staticClass: "text-xs-left" },
                      [_c("v-flex")],
                      1
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-xs-center",
                        attrs: { width: "250px" }
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              link: "",
                              color: "white",
                              light: "",
                              small: "",
                              fab: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.edit(props.item)
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("edit")])],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              link: "",
                              color: "white",
                              light: "",
                              small: "",
                              fab: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.openRemoveDialog(props.item)
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("delete")])],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            }
          },
          {
            key: "pageText",
            fn: function(ref) {
              var pageStart = ref.pageStart
              var pageStop = ref.pageStop
              return [
                _vm._v(
                  " From " + _vm._s(pageStart) + " to " + _vm._s(pageStop) + " "
                )
              ]
            }
          }
        ])
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.deleting,
            callback: function($$v) {
              _vm.deleting = $$v
            },
            expression: "deleting"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "highlight", dark: "" } },
                [_c("v-toolbar-title", [_vm._v(" Delete Game")])],
                1
              ),
              _c("v-card-text", [
                _vm._v("Are you sure want to delete the org called "),
                _c("b", [_vm._v(_vm._s(_vm.org.name))]),
                _vm._v("?")
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "error",
                          attrs: { flat: "" },
                          on: { click: _vm.remove }
                        },
                        [_vm._v("Remove")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "secondary",
                          attrs: { flat: "" },
                          on: {
                            click: function($event) {
                              _vm.deleting = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", width: "768px" },
          model: {
            value: _vm.editing,
            callback: function($$v) {
              _vm.editing = $$v
            },
            expression: "editing"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "highlight", dark: "" } },
                    [
                      _c("v-icon", { attrs: { dark: "" } }, [_vm._v("edit")]),
                      _c("v-toolbar-title", [
                        _vm._v(" " + _vm._s(_vm.org.name))
                      ]),
                      _c("v-spacer"),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        { attrs: { icon: "" }, on: { click: _vm.update } },
                        [_c("v-icon", [_vm._v("saves")])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              _vm.editing = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("cancel")])],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.org
                    ? _c(
                        "v-flex",
                        { attrs: { "mt-4": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { column: "" } },
                            [
                              _c(
                                "v-flex",
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { "mr-2": "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "NAME",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.org.name,
                                              callback: function($$v) {
                                                _vm.$set(_vm.org, "name", $$v)
                                              },
                                              expression: "org.name"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { "ml-2": "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "DESCRIPTION",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.org.description,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.org,
                                                  "description",
                                                  $$v
                                                )
                                              },
                                              expression: "org.description"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { "ml-2": "" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.twilioRegions,
                                              "item-text": "location",
                                              "item-value": "id",
                                              label: "Select Region",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.org.twilioRegion,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.org,
                                                  "twilioRegion",
                                                  $$v
                                                )
                                              },
                                              expression: "org.twilioRegion"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { "mr-2": "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "TWILIO KEY",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.org.twilioKey,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.org,
                                                  "twilioKey",
                                                  $$v
                                                )
                                              },
                                              expression: "org.twilioKey"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { "ml-2": "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "TWILIO SID",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.org.twilioSid,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.org,
                                                  "twilioSid",
                                                  $$v
                                                )
                                              },
                                              expression: "org.twilioSid"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { "ml-2": "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "TWILIO SECRET",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.org.twilioSecret,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.org,
                                                  "twilioSecret",
                                                  $$v
                                                )
                                              },
                                              expression: "org.twilioSecret"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                [
                                  _vm._l(_vm.twitchAccounts, function(
                                    twitchAccount,
                                    index
                                  ) {
                                    return _c(
                                      "v-layout",
                                      { key: index, attrs: { row: "" } },
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { "mr-2": "" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "TWITCH NAME",
                                                required: ""
                                              },
                                              model: {
                                                value:
                                                  _vm.twitchAccounts[index]
                                                    .name,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.twitchAccounts[index],
                                                    "name",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "twitchAccounts[index].name"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          { attrs: { "ml-2": "" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "TWITCH URL",
                                                required: ""
                                              },
                                              model: {
                                                value:
                                                  _vm.twitchAccounts[index].url,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.twitchAccounts[index],
                                                    "url",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "twitchAccounts[index].url"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          { attrs: { "ml-2": "" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "TWITCH API KEY",
                                                required: ""
                                              },
                                              model: {
                                                value:
                                                  _vm.twitchAccounts[index]
                                                    .apiKey,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.twitchAccounts[index],
                                                    "apiKey",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "twitchAccounts[index].apiKey"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          { attrs: { "ml-2": "" } },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteTwitchAccount(
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("delete")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  _c(
                                    "v-layout",
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs4: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Later Comer Video"
                                            },
                                            model: {
                                              value: _vm.org.latecomerVideoCode,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.org,
                                                  "latecomerVideoCode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "org.latecomerVideoCode"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    { on: { click: _vm.addTwitchAccount } },
                                    [_vm._v("Add Account")]
                                  ),
                                  _vm.themeOptions.length > 0
                                    ? _c("v-select", {
                                        attrs: {
                                          items: _vm.themeOptions,
                                          label: "Theme"
                                        },
                                        model: {
                                          value: _vm.org.themeID,
                                          callback: function($$v) {
                                            _vm.$set(_vm.org, "themeID", $$v)
                                          },
                                          expression: "org.themeID"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary",
                          attrs: { flat: "" },
                          on: { click: _vm.update }
                        },
                        [_vm._v("Save")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "secondary",
                          attrs: { flat: "" },
                          on: {
                            click: function($event) {
                              _vm.editing = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }