<template>
  <v-card>
    <v-card-title>
      <h2>Organizations</h2>
      (<span @click="gotoEvents">see all games</span>)
      <v-spacer />

      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      />
    </v-card-title>
    <v-btn circle absolute top small right fab color="orange" @click="add()">
      <v-icon>add</v-icon>
    </v-btn>

    <v-data-table
      ref="orgTable"
      :headers="headers"
      :items="allOrgs"
      :search="search"
      class="elevation-1"
      item-key="theKey"
    >
      <template slot="items" slot-scope="props">
        <!--
          NOTE:  You'll need a unique ID, that is specific to the given item, for the key.
          Not providing a unique key that's bound to the item object will break drag and drop sorting.
          The itemKey method will return a uid for a given object using WeakMap.
          You could just use a property in the object with a unique value, like "props.item.name" in this case,
          but often getting a unique value from the object's properties can be difficult, like when adding new rows,
          or when the unique field is open to editing, etc.
        -->
        <tr
          :key="itemKey(props.item)"
          class="sortableRow"
          @click="props.expanded = !props.expanded"
        >
          <td class="px-1" style="width: 0.1%">
            <v-btn style="cursor: move" icon class="sortHandle"
              ><v-icon>drag_handle</v-icon></v-btn
            >
          </td>
          <td class="text-xs-left">
            <a @click="pushToGames(props.item)">{{ props.item.name }}</a>
          </td>
          <td class="text-xs-left">{{ props.item.description }}</td>
          <td class="text-xs-left"><v-flex /></td>

          <td class="text-xs-center" width="250px">
            <v-btn link color="white" light small fab @click="edit(props.item)">
              <v-icon>edit</v-icon>
            </v-btn>
            <v-btn
              link
              color="white"
              light
              small
              fab
              @click="openRemoveDialog(props.item)"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        From {{ pageStart }} to {{ pageStop }}
      </template>
    </v-data-table>

    <v-dialog v-model="deleting" max-width="500px">
      <v-card>
        <v-toolbar color="highlight" dark>
          <v-toolbar-title> Delete Game</v-toolbar-title>
        </v-toolbar>
        <v-card-text
          >Are you sure want to delete the org called <b>{{ org.name }}</b
          >?</v-card-text
        >
        <v-card-actions>
          <v-layout justify-center>
            <v-btn flat class="error" @click="remove">Remove</v-btn>
            <v-btn flat class="secondary" @click="deleting = false"
              >Cancel</v-btn
            >
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editing" scrollable width="768px">
      <v-card>
        <v-card-text>
          <v-toolbar color="highlight" dark>
            <v-icon dark>edit</v-icon>
            <v-toolbar-title> {{ org.name }}</v-toolbar-title>
            <v-spacer />
            <v-spacer />
            <v-btn icon @click="update">
              <v-icon>saves</v-icon>
            </v-btn>
            <v-btn icon @click="editing = false">
              <v-icon>cancel</v-icon>
            </v-btn>
          </v-toolbar>
          <v-flex mt-4 v-if="org">
            <v-layout column>
              <v-flex>
                <v-layout row>
                  <v-flex mr-2>
                    <v-text-field v-model="org.name" label="NAME" required />
                  </v-flex>
                  <v-flex ml-2>
                    <v-text-field
                      v-model="org.description"
                      label="DESCRIPTION"
                      required
                    />
                  </v-flex>
                  <v-flex ml-2>
                    <v-select
                      :items="twilioRegions"
                      v-model="org.twilioRegion"
                      item-text="location"
                      item-value="id"
                      label="Select Region"
                      required
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex>
                <v-layout row>
                  <v-flex mr-2>
                    <v-text-field
                      v-model="org.twilioKey"
                      label="TWILIO KEY"
                      required
                    />
                  </v-flex>
                  <v-flex ml-2>
                    <v-text-field
                      v-model="org.twilioSid"
                      label="TWILIO SID"
                      required
                    />
                  </v-flex>
                  <v-flex ml-2>
                    <v-text-field
                      v-model="org.twilioSecret"
                      label="TWILIO SECRET"
                      required
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex>
                <v-layout
                  row
                  v-for="(twitchAccount, index) in twitchAccounts"
                  :key="index"
                >
                  <v-flex mr-2>
                    <v-text-field
                      label="TWITCH NAME"
                      required
                      v-model="twitchAccounts[index].name"
                    />
                  </v-flex>
                  <v-flex ml-2>
                    <v-text-field
                      label="TWITCH URL"
                      required
                      v-model="twitchAccounts[index].url"
                    />
                  </v-flex>
                  <v-flex ml-2>
                    <v-text-field
                      label="TWITCH API KEY"
                      required
                      v-model="twitchAccounts[index].apiKey"
                    />
                  </v-flex>
                  <v-flex ml-2>
                    <v-btn @click="deleteTwitchAccount(index)">delete</v-btn>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs4>
                    <v-text-field
                      v-model="org.latecomerVideoCode"
                      label="Later Comer Video"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-btn @click="addTwitchAccount">Add Account</v-btn>
                <v-select
                  v-if="themeOptions.length > 0"
                  v-model="org.themeID"
                  :items="themeOptions"
                  label="Theme"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-layout justify-center>
            <v-btn flat class="primary" @click="update">Save</v-btn>
            <v-btn flat class="secondary" @click="editing = false"
              >Cancel</v-btn
            >
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Vue from "vue"
import { mapActions } from "vuex"
import firebase from "firebase/app"
import "firebase/database"

import { TWILIO_REGIONS } from "@/services/twilio.service"

import { ActionTypes as OrgModuleActionTypes } from "@/store/OrgModule"

import { SelectManager } from "@/helpers"

export default Vue.extend({
  metaInfo: {
    title: "Games"
  },
  filters: {
    truncate: function (value, limit) {
      if (value.length > limit) {
        value = value.substring(0, limit - 3) + "..."
      }
      return value
    }
  },
  data() {
    return {
      itemKeys: new WeakMap(),
      currentItemKey: 0,
      org: {
        name: "",
        description: ""
      },
      viewing: false,
      items: [],
      deleting: false,
      editing: false,
      adding: false,
      tmp: "",
      search: "",
      pagination: {},
      user: this.$store.getters.user,
      headers: [
        {
          sortable: false
        },
        {
          text: "Organization",
          value: "avatar",
          align: "left",
          sortable: false
        },
        {
          text: "Description",
          value: "description",
          align: "left",
          sortable: false
        },
        {
          text: "Games",
          value: "missions",
          align: "left",
          sortable: false
        },
        {
          text: "Action",
          value: "action",
          align: "left",
          sortable: false
        }
      ],
      twitchAccounts: [],
      twilioRegions: TWILIO_REGIONS,
      localOrgChanges: {}
    }
  },
  computed: {
    getOrgsObject() {
      return this.$store.getters.orgs || {}
    },
    themeOptions() {
      const value = [{ value: null, text: "Default" }]
      return this.org && this.org.themes
        ? value.concat(SelectManager.normalize(this.org.themes, "name"))
        : value
    },
    allOrgs() {
      console.log("load data")
      const orgsArray = Object.entries(this.getOrgsObject)
      return orgsArray.map(([orgID, org]) => ({ theKey: orgID, ...org }))
    }
  },
  watch: {
    editing(value) {
      this.cancel(value)
      if (!value) {
        this.org = {
          name: "",
          description: ""
        }
      }
    },
    viewing(to) {
      this.cancel(to)
    },
    deleting(to) {
      this.cancel(to)
    }
  },
  async created() {
    await this.$store.dispatch(OrgModuleActionTypes.FETCH_ORGS)
  },
  mounted() {
    this.$refs.orgTable.defaultPagination.rowsPerPage = 20
  },
  methods: {
    ...mapActions("pregame", ["updateMessage"]),
    gotoEvents() {
      this.$router.push("/events")
    },
    async pushToGames(org) {
      await this.$store.dispatch("setOrgID", org.theKey)
      await this.$router.push("/games")
    },
    itemKey(item) {
      if (!this.itemKeys.has(item))
        this.itemKeys.set(item, ++this.currentItemKey)
      return this.itemKeys.get(item)
    },
    resetForm() {
      this.org = {
        name: "",
        avatar: ""
      }
    },
    cancel(to) {
      if (to === false) {
        this.resetForm()
      }
    },
    view(org) {
      this.viewing = true
      this.org = { ...org }
    },
    closeModalDetail() {
      this.viewing = false
      this.org = null
    },
    openRemoveDialog(org) {
      this.org = { ...org }
      this.deleting = true
    },
    remove() {
      this.$store.dispatch("removeOrg", this.org)
      this.deleting = false
    },
    edit(org) {
      this.editing = true
      this.org = { ...org }
      this.twitchAccounts = Object.keys(org.twitchAccounts || {}).map(key => {
        return {
          key,
          ...org.twitchAccounts[key]
        }
      })
    },
    add() {
      this.editing = true
      this.adding = true
      this.org = {
        name: "",
        description: ""
      }
    },
    update() {
      console.log(this.org)
      // console.log(this.adding)
      if (this.adding) {
        console.log("adding")
        console.log(this.org)
        this.org.addedBy = this.user.name
        this.$store.dispatch("addOrg", this.org)
      } else {
        console.log("editing")
        console.log(this.org)
        this.$store.dispatch("updateOrg", {
          ...this.org,
          twitchAccounts: this.prepareTwitchAccounts()
        })
      }
      this.editing = false
      this.$store.dispatch(OrgModuleActionTypes.FETCH_ORGS)
    },
    async save() {
      this.org.avatar = "http://via.placeholder.com/480x200"
      this.org.id = this.items.length + 1
      console.log("time - " + this.org.time)
      this.items.push(this.org)
      this.resetForm()
      this.adding = false
      console.log("saving")
      this.$store.dispatch("updateOrgs")
    },
    prepareTwitchAccounts() {
      const obj = {}
      this.twitchAccounts.forEach(twitch => {
        const orgKey = this.org.theKey
        const key =
          twitch.key !== 0
            ? twitch.key
            : firebase.database().ref(`orgs/${orgKey}/twitchAccounts`).push()
                .key
        obj[key] = {
          name: twitch.name || "",
          apiKey: twitch.apiKey || "",
          url: twitch.url || ""
        }
      })
      return obj
    },
    addTwitchAccount() {
      this.twitchAccounts.push({
        key: 0,
        name: "",
        apiKey: "",
        url: ""
      })
    },
    deleteTwitchAccount(index) {
      if (!confirm("You are deleting this twitch account")) return
      this.twitchAccounts = this.twitchAccounts.filter(
        (twitch, indx) => indx !== index
      )
    }
  }
})
</script>
